/* @import url('./assets//fonts/LondrinaSolid-Black.ttf');
@import url('./assets/fonts/LondrinaSolid-Thin.ttf'); */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  color: white;
  font-family: "Montserrat", sans-serif;
}

body {
  background: linear-gradient(90deg, #410D48, #8D174F, #E02256);
  /* overflow-x: hidden; */
}
b {
  font-weight: 600;
  color: inherit;
}
html {
  scroll-behavior: smooth;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
